import React, { Suspense, Fragment, lazy } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import Loader from './components/Loader/Loader'
import AdminLayout from './layouts/AdminLayout'

import { BASE_URL } from './config/constant'

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {routes.map((route, i) => {
        const Layout = route.layout || Fragment
        const Component = route.component

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Layout>
                {route.routes ? (
                  renderRoutes(route.routes)
                ) : (
                  <Component {...props} />
                )}
              </Layout>
            )}
          />
        )
      })}
    </Switch>
  </Suspense>
)

const routes = [
  {
    exact: true,
    path: '/auth/signin-1',
    component: lazy(() => import('./views/auth/signin/SignIn1')),
  },
  {
    exact: true,
    path: '/auth/signup-1',
    component: lazy(() => import('./views/auth/signup/SignUp1')),
  },
  {
    exact: true,
    path: '/auth/forgotPassword',
    component: lazy(() => import('./views/auth/forgotPassword/forgotPassword')),
  },
  {
    exact: true,
    path: '/auth/changePassword',
    component: lazy(() => import('./views/auth/changePassword/changePassword')),
  },
  {
    path: '*',
    layout: AdminLayout,
    routes: [
      {
        exact: true,
        path: '/app/dashboard/default',
        component: lazy(() => import('./views/dashboard/DashDefault')),
      },
      {
        exact: true,
        path: '/app/userManagement/default',
        component: lazy(() => import('./views/userManagement/userManagement')),
      },
      {
        exact: true,
        path: '/app/userManagement/userDetails/:id',
        component: lazy(() =>
          import('./views/userManagement/userDetails/userDetails')
        ),
      },
      {
        exact: true,
        path: '/app/reportUserManagement/default',
        component: lazy(() => import('./views/reportedUsers/ReportedUsers')),
      },
      {
        exact: true,
        path: '/app/backgroundCheckManagement/pending/default',
        component: lazy(() =>
          import('./views/backgroundCheck/BackgroundCheck')
        ),
      },
      {
        exact: true,
        path: '/app/backgroundCheckManagement/completed/default',
        component: lazy(() =>
          import('./views/backgroundCheck/BackgroundCheck')
        ),
      },
      {
        exact: true,
        path: '/app/subscriptionManagement/default',
        component: lazy(() =>
          import(
            './views/subscriptionPlanManagement/SubscriptionPlanManagement'
          )
        ),
      },
      {
        exact: true,
        path: '/app/advertiseManagement/default',
        component: lazy(() =>
          import('./views/advertisement/advertisementManagement')
        ),
      },
      {
        exact: true,
        path: '/app/interestsManagement/travelingWith/default',
        component: lazy(() =>
          import('./views/interestsManagement/travelingWith/travelingWith')
        ),
      },
      {
        exact: true,
        path: '/app/interestsManagement/typesOfTraveling/default',
        component: lazy(() =>
          import(
            './views/interestsManagement/typesOfTraveling/typesOfTraveling'
          )
        ),
      },
      {
        exact: true,
        path: '/app/interestsManagement/activities/default',
        component: lazy(() =>
          import('./views/interestsManagement/activities/activities')
        ),
      },
      {
        exact: true,
        path: '/app/cmsManagement/termsAndConditions/default',
        component: lazy(() =>
          import('./views/cmsManagement/termsAndConditions/termsAndConditions')
        ),
      },
      {
        exact: true,
        path: '/app/cmsManagement/privacyPolicy/default',
        component: lazy(() =>
          import('./views/cmsManagement/privacyPolicy/privacyPolicy')
        ),
      },
      {
        exact: true,
        path: '/app/cmsManagement/aboutUs/default',
        component: lazy(() => import('./views/cmsManagement/aboutUs/aboutUs')),
      },
      {
        exact: true,
        path: '/app/cmsManagement/contactUs/default',
        component: lazy(() =>
          import('./views/cmsManagement/contactUs/contactUs')
        ),
      },
      {
        exact: true,
        path: '/app/cmsManagement/faq/default',
        component: lazy(() => import('./views/cmsManagement/faq/faq')),
      },
      {
        exact: true,
        path: '/app/adminProfile/default',
        component: lazy(() => import('./views/adminProfile/adminProfile')),
      },
      {
        exact: true,
        path: '/basic/badges',
        component: lazy(() => import('./views/ui-elements/basic/BasicBadges')),
      },
      {
        exact: true,
        path: '/basic/breadcrumb',
        component: lazy(() =>
          import('./views/ui-elements/basic/BasicBreadcrumb')
        ),
      },
      {
        exact: true,
        path: '/basic/pagination',
        component: lazy(() =>
          import('./views/ui-elements/basic/BasicPagination')
        ),
      },
      {
        exact: true,
        path: '/basic/collapse',
        component: lazy(() =>
          import('./views/ui-elements/basic/BasicCollapse')
        ),
      },
      {
        exact: true,
        path: '/basic/tabs-pills',
        component: lazy(() =>
          import('./views/ui-elements/basic/BasicTabsPills')
        ),
      },
      {
        exact: true,
        path: '/basic/typography',
        component: lazy(() =>
          import('./views/ui-elements/basic/BasicTypography')
        ),
      },
      {
        exact: true,
        path: '/forms/form-basic',
        component: lazy(() => import('./views/forms/FormsElements')),
      },
      {
        exact: true,
        path: '/tables/bootstrap',
        component: lazy(() => import('./views/tables/BootstrapTable')),
      },
      {
        exact: true,
        path: '/charts/nvd3',
        component: lazy(() => import('./views/charts/nvd3-chart')),
      },
      {
        exact: true,
        path: '/maps/google-map',
        component: lazy(() => import('./views/maps/GoogleMaps')),
      },
      {
        exact: true,
        path: '/sample-page',
        component: lazy(() => import('./views/extra/SamplePage')),
      },
      {
        path: '*',
        exact: true,
        component: () => <Redirect to={BASE_URL} />,
      },
    ],
  },
]

export default routes
