import React from 'react'
// import * as MuiIcons from '@material-ui/icons'

const NavIcon = ({ items }) => {
    let navIcons = false
    if (items.icon) {
        navIcons = (
            <span
                className='pcoded-micon material-icons-outlined'
                style={{ fontSize: '1.4rem' }}
            >
                {/* <i className={items.icon} /> */}
                {items.iconComponent}
            </span>
        )
    }

    return <React.Fragment>{navIcons}</React.Fragment>
}

export default NavIcon
