import axiosServices from '../../utils/axios'

// const API_URL = 'https://api.travelpalsglobal.com
const API_URL = process.env.REACT_APP_BACKEND_URL
// const API_URL = 'http://192.168.1.137:4000'

const API_ROUTES = '/api/v1/admin/'

let TOKEN = JSON.parse(localStorage.getItem('travelpals_token'))

const headers = {}
headers['language'] = 'english'
headers['authorization'] = 'Bearer ' + TOKEN

const ApiUtils = {
  login: function (payload) {
    return axiosServices
      .post(API_ROUTES + 'login', payload)
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  logout: function (payload) {
    return axiosServices
      .post(API_URL + API_ROUTES + 'logout', payload)
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  getAdminProfile: function (payload) {
    return axiosServices
      .get(API_URL + API_ROUTES + 'profile')
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  editAdminProfile: function (payload) {
    return axiosServices
      .post(API_URL + API_ROUTES + 'edit-profile', payload)
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  getCountryList: function (payload) {
    let token = JSON.parse(localStorage.getItem('travelpals_token'))
    return axiosServices
      .post(API_URL + API_ROUTES + 'countries', payload)
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  changeAdminPassword: function (payload) {
    return axiosServices
      .post(API_URL + API_ROUTES + 'change-password', payload)
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  viewInterest: function (payload) {
    return axiosServices
      .post(
        API_URL +
          API_ROUTES +
          `view-interest?page=${payload.page}&limits=${payload.limits}&sortValue=${payload.sortValue}&search=${payload.search}`,
        payload.body
      )
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  addInterest: function (payload) {
    return axiosServices
      .post(API_URL + API_ROUTES + 'add-interest', payload)
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  updateInterest: function (payload) {
    return axiosServices
      .post(API_URL + API_ROUTES + 'update-interest', payload)
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  deleteInterest: function (payload) {
    return axiosServices
      .post(API_URL + API_ROUTES + 'delete-interest', payload)
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  getCmsContent: function (payload) {
    return axiosServices
      .post(API_URL + API_ROUTES + 'cms-content', payload)
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  updateCmsContent: function (payload) {
    return axiosServices
      .post(API_URL + API_ROUTES + 'cms-content/update', payload)
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  getUserList: function (payload) {
    return axiosServices
      .post(
        API_URL +
          API_ROUTES +
          `get-users?page=${payload.page}&limits=${payload.limits}&search_string=${payload.search_string}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  getUserDetails: function (payload) {
    return axiosServices
      .post(API_URL + API_ROUTES + 'user', payload)
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  getdashboardDetails: function (payload) {
    return axiosServices
      .get(API_URL + API_ROUTES + 'dashboard', payload)
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  // Get all FAQ
  getAllFaq: function (payload) {
    return axiosServices
      .post(API_URL + API_ROUTES + 'get-all-faqs', payload)
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  // Add FAQ
  addFaq: function (payload) {
    return axiosServices
      .post(API_URL + API_ROUTES + 'add-faq', payload)
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  // Add FAQ
  addOrEditFaq: function (payload) {
    return axiosServices
      .post(API_URL + API_ROUTES + 'add-faq', payload)
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  // Delete FAQ
  deleteFaq: function (payload) {
    return axiosServices
      .post(API_URL + API_ROUTES + 'delete-faq', payload)
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  // For listing advertisement
  getAvertisements: function (payload) {
    return axiosServices
      .get(
        API_URL +
          API_ROUTES +
          `advertisement/list?page=${payload.page + 1}&limits=${
            payload.limit
          }&search=${payload.search}`,
        payload
      )
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  // For adding advertisement
  addAdvertisement: function (payload) {
    return axiosServices
      .post(API_URL + API_ROUTES + 'advertisement/create', payload)
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  // For editing advertisement
  editAdvertisement: function (payload) {
    return axiosServices
      .post(API_URL + API_ROUTES + 'advertisement/edit-advertisement', payload)
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  // For delete advertisement
  deleteAdvertisement: function (payload) {
    return axiosServices
      .post(
        API_URL + API_ROUTES + 'advertisement/delete-advertisement',
        payload
      )
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  // For getting reported users list
  getReportedUsers: function (payload) {
    return axiosServices
      .get(
        API_URL +
          API_ROUTES +
          `reported-user?page=${payload.page}&limits=${
            payload.limit
          }&search_string=${payload.search || ''}`
      )
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  // For blocking user from block user management
  blockUser: function (payload) {
    return axiosServices
      .post(API_URL + API_ROUTES + 'block-user', payload)
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  // For unblocking user from block user management
  unblockUser: function (payload) {
    return axiosServices
      .post(API_URL + API_ROUTES + 'unblock-user', payload)
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  // For getting all subscription data
  getSubscriptionData: function (payload) {
    return axiosServices
      .get(
        API_URL +
          API_ROUTES +
          `subscription-list?page=${payload.page}&limits=${
            payload.limits
          }&search_string=${payload.search ? payload.search : ''}`
      )
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  // For adding a subscription plan
  addSubscriptionPlan: function (payload) {
    return axiosServices
      .post(API_URL + API_ROUTES + 'create-subscription', payload)
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  // For editing a subscription plan
  editSubscriptionPlan: function (payload) {
    return axiosServices
      .post(API_URL + API_ROUTES + 'edit-subscription', payload)
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  // For deleting a subscription plan
  deleteSubscriptionPlan: function (payload) {
    return axiosServices
      .post(API_URL + API_ROUTES + 'delete-subscription', payload)
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  // For getting user data for background checks
  getBackgroundCheckList: function (payload) {
    return axiosServices
      .post(
        API_URL +
          API_ROUTES +
          `background/admin-background-check-list?page=${payload.page}&limits=${
            payload.limit
          }&search_string=${payload.search ? payload.search : ''}`,
        payload.payload
      )
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  // For performing a background check on a user
  performBackgrounCheck: function (payload) {
    return axiosServices
      .post(API_URL + API_ROUTES + 'background/admin-background-check', payload)
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },

  // For completing a background check on a user
  completeBackgrounCheck: function (payload) {
    return axiosServices
      .post(
        API_URL + API_ROUTES + 'background/admin-background-check-completed',
        payload
      )
      .then((response) => {
        if (response && response.data) {
          return response.data
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data
      })
  },
}

export default ApiUtils
