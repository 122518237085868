// import {
//     HomeOutlined,
//     PeopleOutline,
//     TravelExploreOutlined,
//     SettingsOutlined,
// } from '@mui/icons-material'
const menuItems = {
  items: [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          url: '/app/dashboard/default',
          icon: 'feather icon-home',
          iconComponent: 'home',
        },
        {
          id: 'user-management',
          title: 'User management',
          type: 'item',
          url: '/app/userManagement/default',
          icon: 'feather icon-users',
          iconComponent: 'people',
        },
        {
          id: 'reported-management',
          title: 'Report user management',
          type: 'item',
          icon: 'feather icon-users',
          url: '/app/reportUserManagement/default',
          iconComponent: 'people',
        },
        {
          id: 'background-check-management',
          title: 'Background check management',
          type: 'collapse',
          icon: 'feather icon-users',
          iconComponent: 'check',
          children: [
            {
              id: 'Pending',
              title: 'Pending Background Check',
              type: 'item',
              url: '/app/backgroundCheckManagement/pending/default',
            },
            {
              id: 'Completed',
              title: 'Completed Background Check',
              type: 'item',
              url: '/app/backgroundCheckManagement/completed/default',
            },
          ],
        },
        {
          id: 'advertise-management',
          title: 'Advertise management',
          type: 'item',
          url: '/app/advertiseManagement/default',
          icon: 'feather icon-users',
          iconComponent: 'people',
        },
        {
          id: 'subscription-management',
          title: 'Subscription management',
          type: 'item',
          url: '/app/subscriptionManagement/default',
          icon: 'feather icon-users',
          iconComponent: 'people',
        },
        {
          id: 'interest-management',
          title: 'Interest management',
          type: 'collapse',
          icon: 'feather icon-box',
          iconComponent: 'psychology',
          children: [
            {
              id: 'traveling-with',
              title: 'Traveling With',
              type: 'item',
              url: '/app/interestsManagement/travelingWith/default',
            },
            {
              id: 'type-of-traveling',
              title: 'Types Of Traveling',
              type: 'item',
              url: '/app/interestsManagement/typesOfTraveling/default',
            },
            {
              id: 'activities',
              title: 'Activities',
              type: 'item',
              url: '/app/interestsManagement/activities/default',
            },
          ],
        },
        // {
        //     id: 'suspension-management',
        //     title: 'Suspension Management',
        //     type: 'item',
        //     url: '/forms/form-basic',
        //     icon: 'feather icon-globe',
        //     iconComponent: 'person_off',
        // },
        // {
        //     id: 'promotion-management',
        //     title: 'Promotion Management',
        //     type: 'item',
        //     url: '/charts/nvd3',
        //     icon: 'feather icon-globe',
        //     iconComponent: 'spatial_audio_off',
        // },
        // {
        //     id: 'notification-panel',
        //     title: 'Notification Panel',
        //     type: 'item',
        //     url: '/charts/nvd3',
        //     icon: 'feather icon-bell',
        // },
        // {
        //     id: 'payment-logs',
        //     title: 'Payment Logs',
        //     type: 'item',
        //     url: '/charts/nvd3',
        //     icon: 'feather icon-credit-card',
        // },
        // {
        //     id: 'subscription-plan-management',
        //     title: 'Subscription Plan Management',
        //     type: 'item',
        //     url: '/charts/nvd3',
        //     icon: 'feather icon-refresh-cw',
        // },
        {
          id: 'cms-management',
          title: 'CMS management',
          type: 'collapse',
          icon: 'feather icon-box',
          iconComponent: 'connect_without_contact',
          children: [
            {
              id: 'terms-and-conditions',
              title: 'Terms & Conditions',
              type: 'item',
              url: '/app/cmsManagement/termsAndConditions/default',
            },
            {
              id: 'privacy-policy',
              title: 'Privacy Policy',
              type: 'item',
              url: '/app/cmsManagement/privacyPolicy/default',
            },
            {
              id: 'about-us',
              title: 'About us',
              type: 'item',
              url: '/app/cmsManagement/aboutUs/default',
            },
            {
              id: 'contact-us',
              title: 'Contact Us',
              type: 'item',
              url: '/app/cmsManagement/contactUs/default',
            },
            {
              id: 'faq',
              title: 'FAQ',
              type: 'item',
              url: '/app/cmsManagement/faq/default',
            },
          ],
        },

        // {
        //     id: 'settings',
        //     title: 'Settings',
        //     type: 'item',
        //     url: '/maps/google-map',
        //     icon: 'feather icon-settings',
        //     iconComponent: 'settings',
        // },
      ],
    },
    // {
    //   id: 'ui-element',
    //   title: 'UI ELEMENT',
    //   type: 'group',
    //   icon: 'icon-ui',
    //   children: [
    //     {
    //       id: 'basic',
    //       title: 'Component',
    //       type: 'collapse',
    //       icon: 'feather icon-box',
    //       children: [

    //         {
    //           id: 'button',
    //           title: 'Button',
    //           type: 'item',
    //           url: '/basic/button'
    //         },
    //         {
    //           id: 'badges',
    //           title: 'Badges',
    //           type: 'item',
    //           url: '/basic/badges'
    //         },
    //         {
    //           id: 'breadcrumb',
    //           title: 'Breadcrumb',
    //           type: 'item',
    //           url: '/basic/breadcrumb'
    //         },
    //         {
    //           id: 'pagination',
    //           title: 'Pagination',
    //           type: 'item',
    //           url: '/basic/pagination'
    //       },
    //         {
    //           id: 'collapse',
    //           title: 'Collapse',
    //           type: 'item',
    //           url: '/basic/collapse'
    //         },
    //         {
    //           id: 'tabs-pills',
    //           title: 'Tabs & Pills',
    //           type: 'item',
    //           url: '/basic/tabs-pills'
    //         },
    //         {
    //           id: 'typography',
    //           title: 'Typography',
    //           type: 'item',
    //           url: '/basic/typography'
    //         }
    //       ]
    //     }
    //   ]
    // },
    // {
    //   id: 'forms-tables',
    //   title: 'Forms & Tables',
    //   type: 'group',
    //   icon: 'icon-group',
    //   children: [
    //     {
    //       id: 'forms',
    //       title: 'Form Elements',
    //       type: 'item',
    //       url: '/forms/form-basic',
    //       icon: 'feather icon-file-text',
    //     },
    //     {
    //       id: 'tables',
    //       title: 'Table',
    //       type: 'item',
    //       url: '/tables/bootstrap',
    //       icon: 'feather icon-server',
    //     }
    //   ]
    // },
    // {
    //   id: 'chart-maps',
    //   title: 'Chart & Maps',
    //   type: 'group',
    //   icon: 'icon-charts',
    //   children: [
    //     {
    //       id: 'charts',
    //       title: 'Charts',
    //       type: 'item',
    //       url: '/charts/nvd3',
    //       icon: 'feather icon-pie-chart'
    //     },
    //     {
    //       id: 'maps',
    //       title: 'Map',
    //       type: 'item',
    //       url: '/maps/google-map',
    //       icon: 'feather icon-map'
    //     }
    //   ]
    // },
    // {
    //   id: 'pages',
    //   title: 'Pages',
    //   type: 'group',
    //   icon: 'icon-pages',
    //   children: [
    //     {
    //       id: 'auth',
    //       title: 'Authentication',
    //       type: 'collapse',
    //       icon: 'feather icon-lock',
    //       badge: {
    //         title: 'New',
    //         type: 'label-danger'
    //     },
    //       children: [
    //         {
    //           id: 'signup-1',
    //           title: 'Sign up',
    //           type: 'item',
    //           url: '/auth/signup-1',
    //           target: true,
    //           breadcrumbs: false
    //         },

    //         {
    //           id: 'signin-1',
    //           title: 'Sign in',
    //           type: 'item',
    //           url: '/auth/signin-1',
    //           target: true,
    //           breadcrumbs: false
    //         }
    //       ]
    //     },
    //     {
    //       id: 'sample-page',
    //       title: 'Sample Page',
    //       type: 'item',
    //       url: '/sample-page',
    //       classes: 'nav-item',
    //       icon: 'feather icon-sidebar'
    //     },
    //     {
    //       id: 'documentation',
    //       title: 'Documentation',
    //       type: 'item',
    //       icon: 'feather icon-help-circle',
    //       classes: 'nav-item',
    //       url: 'https://codedthemes.com/item/datta-able-react-free-admin-template/#',
    //       target: true,
    //       external: true
    //     },
    //     {
    //       id: 'menu-level',
    //       title: 'Menu Levels',
    //       type: 'collapse',
    //       icon: 'feather icon-menu',
    //       children: [
    //         {
    //           id: 'menu-level-1.1',
    //           title: 'Menu Level 1.1',
    //           type: 'item',
    //           url: '#!'
    //         },
    //         {
    //           id: 'menu-level-1.2',
    //           title: 'Menu Level 2.2',
    //           type: 'collapse',
    //           children: [
    //             {
    //               id: 'menu-level-2.1',
    //               title: 'Menu Level 2.1',
    //               type: 'item',
    //               url: '#'
    //             },
    //             {
    //               id: 'menu-level-2.2',
    //               title: 'Menu Level 2.2',
    //               type: 'collapse',
    //               children: [
    //                 {
    //                   id: 'menu-level-3.1',
    //                   title: 'Menu Level 3.1',
    //                   type: 'item',
    //                   url: '#'
    //                 },
    //                 {
    //                   id: 'menu-level-3.2',
    //                   title: 'Menu Level 3.2',
    //                   type: 'item',
    //                   url: '#'
    //                 }
    //               ]
    //             }
    //           ]
    //         }
    //       ]
    //     },
    //     {
    //       id: 'disabled-menu',
    //       title: 'Disabled Menu',
    //       type: 'item',
    //       url: '#',
    //       classes: 'nav-item disabled',
    //       icon: 'feather icon-power'
    //     }
    //   ]
    // }
  ],
}

export default menuItems
