import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { ConfigContext } from '../../../../contexts/ConfigContext';
import * as actionType from '../../../../store/actions';
import travelpals from '../../../../assets/images/logo/travelpals.png';
import smallLogo from '../../../../assets/images/logo/small_logo.png';



const NavLogo = () => {
  const configContext = useContext(ConfigContext);
  const { collapseMenu } = configContext.state;
  const { dispatch } = configContext;

  let toggleClass = ['mobile-menu'];
  if (collapseMenu) {
    toggleClass = [...toggleClass, 'on'];
  }

  return (
    <React.Fragment>
      <div className="navbar-brand header-logo">
        <Link to="#" className="b-brand">
          {/* <div className='b-bg'> */}
            {/* <i className="feather icon-trending-up" /> */}
            <img src={smallLogo} width="50px" className='small-nav-logo'/>
          {/* </div> */}
          <img src={travelpals} width="85%" className='b-title ml-0'/>
          {/* <span className="b-title">Datta Able</span> */}
        </Link>
        <Link to="#" className={toggleClass.join(' ')} id="mobile-collapse" onClick={() => dispatch({ type: actionType.COLLAPSE_MENU })}>
          <span />
        </Link>
      </div>
    </React.Fragment>
  );
};

export default NavLogo;
