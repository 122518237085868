import React, { useContext, useEffect, useState } from 'react'
import { ListGroup, Dropdown, Media } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'

import ChatList from './ChatList'
import { ConfigContext } from '../../../../contexts/ConfigContext'
import useAuth from '../../../../hooks/useAuth'

import avatar1 from '../../../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../../../assets/images/user/avatar-1.jpg'
import avatar3 from '../../../../assets/images/user/avatar-3.jpg'
import avatar4 from '../../../../assets/images/user/avatar-4.jpg'

import ChangePassword from '../../../../views/adminProfile/changePassword/changePassword'

import Api from '../../../../components/helper/ApiUtils'

import { toast } from 'react-toastify'
import { notification, Spin } from 'antd'

import { useHistory } from 'react-router-dom'

const NavRight = () => {
    const configContext = useContext(ConfigContext)
    const { logout } = useAuth()
    const { rtlLayout } = configContext.state

    const [listOpen, setListOpen] = useState(false)
    const [isShowChangePassword, setShowChangePassword] = useState(false)

    const [loader, setLoader] = useState(false)

    const history = useHistory()
    const userData = JSON.parse(localStorage.getItem('user'))

    const handleLogout = async () => {
        try {
            Api.logout()
                .then((response) => {
                    if (response.code === 200) {
                        setLoader(false)
                        toast.success(response.msg)
                        localStorage.removeItem('travelpals_token')
                        localStorage.removeItem('user')
                        history.push('/auth/signin-1')
                    } else {
                        localStorage.removeItem('travelpals_token')
                        localStorage.removeItem('user')
                        history.push('/auth/signin-1')
                        setLoader(false)
                    }
                })
                .catch((error) => {
                    setLoader(false)
                    localStorage.removeItem('travelpals_token')
                    localStorage.removeItem('user')
                    history.push('/auth/signin-1')
                })

            //handleClose();
            await logout()
        } catch (err) {
            console.error(err)
        }
    }

    const handleEditUserProfile = () => {
        history.push('/app/adminProfile/default')
    }

    const showChangePasswordModal = () => {
        setShowChangePassword(true)
    }
    const handleChangePassword = (data) => {
        setLoader(true)
        let payload = {
            old_password: data.currentPassword,
            new_password: data.newPassword,
            confirm_password: data.confirmPassword,
        }
        Api.changeAdminPassword(payload)
            .then((response) => {
                if (response.code == 200 || response.code == 201) {
                    setLoader(false)
                    toast.success(response.msg)
                    setShowChangePassword(false)
                    setTimeout(() => {
                        handleLogout()
                    }, 1000)
                } else {
                    setLoader(false)
                    toast.error(response.msg)
                }
            })
            .catch((error) => {
                setLoader(false)
                toast.error(error.msg)
            })
    }
    const cancelChangePassword = () => {
        setShowChangePassword(false)
    }
    const token =
        localStorage.getItem('travelpals_token') != null
            ? localStorage.getItem('travelpals_token')
            : null
    useEffect(() => {
        if (token == null) {
            history.push('/auth/signin-1')
        }
    }, [])

    return (
        <React.Fragment>
            {loader ? (
                <span className='loader-class'>
                    <Spin size='large' />
                </span>
            ) : (
                ''
            )}
            <ListGroup
                as='ul'
                bsPrefix=' '
                className='navbar-nav ml-auto'
                id='navbar-right'
            >
                {/* <ListGroup.Item as='li' bsPrefix=' '>
                    <Dropdown alignRight={!rtlLayout}>
                        <Dropdown.Toggle
                            as={Link}
                            variant='link'
                            to='#'
                            id='dropdown-basic'
                        >
                            <i className='feather icon-bell icon' />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            alignRight
                            className='notification notification-scroll'
                        >
                            <div className='noti-head'>
                                <h6 className='d-inline-block m-b-0'>
                                    Notifications
                                </h6>
                                <div className='float-right'>
                                    <Link to='#' className='m-r-10'>
                                        mark as read
                                    </Link>
                                    <Link to='#'>clear all</Link>
                                </div>
                            </div>
                            <PerfectScrollbar>
                                <ListGroup
                                    as='ul'
                                    bsPrefix=' '
                                    variant='flush'
                                    className='noti-body'
                                >
                                    <ListGroup.Item
                                        as='li'
                                        bsPrefix=' '
                                        className='n-title'
                                    >
                                        <p className='m-b-0'>NEW</p>
                                    </ListGroup.Item>
                                    <ListGroup.Item
                                        as='li'
                                        bsPrefix=' '
                                        className='notification'
                                    >
                                        <Media>
                                            <img
                                                className='img-radius'
                                                src={avatar1}
                                                alt='Generic placeholder'
                                            />
                                            <Media.Body>
                                                <p>
                                                    <strong>John Doe</strong>
                                                    <span className='n-time text-muted'>
                                                        <i className='icon feather icon-clock m-r-10' />
                                                        30 min
                                                    </span>
                                                </p>
                                                <p>New ticket Added</p>
                                            </Media.Body>
                                        </Media>
                                    </ListGroup.Item>
                                    <ListGroup.Item
                                        as='li'
                                        bsPrefix=' '
                                        className='n-title'
                                    >
                                        <p className='m-b-0'>EARLIER</p>
                                    </ListGroup.Item>
                                    <ListGroup.Item
                                        as='li'
                                        bsPrefix=' '
                                        className='notification'
                                    >
                                        <Media>
                                            <img
                                                className='img-radius'
                                                src={avatar2}
                                                alt='Generic placeholder'
                                            />
                                            <Media.Body>
                                                <p>
                                                    <strong>
                                                        Joseph William
                                                    </strong>
                                                    <span className='n-time text-muted'>
                                                        <i className='icon feather icon-clock m-r-10' />
                                                        30 min
                                                    </span>
                                                </p>
                                                <p>
                                                    Purchase New Theme and make
                                                    payment
                                                </p>
                                            </Media.Body>
                                        </Media>
                                    </ListGroup.Item>
                                    <ListGroup.Item
                                        as='li'
                                        bsPrefix=' '
                                        className='notification'
                                    >
                                        <Media>
                                            <img
                                                className='img-radius'
                                                src={avatar3}
                                                alt='Generic placeholder'
                                            />
                                            <Media.Body>
                                                <p>
                                                    <strong>
                                                        Sara Soudein
                                                    </strong>
                                                    <span className='n-time text-muted'>
                                                        <i className='icon feather icon-clock m-r-10' />
                                                        30 min
                                                    </span>
                                                </p>
                                                <p>currently login</p>
                                            </Media.Body>
                                        </Media>
                                    </ListGroup.Item>
                                    <ListGroup.Item
                                        as='li'
                                        bsPrefix=' '
                                        className='notification'
                                    >
                                        <Media>
                                            <img
                                                className='img-radius'
                                                src={avatar4}
                                                alt='Generic placeholder'
                                            />
                                            <Media.Body>
                                                <p>
                                                    <strong>Suzen</strong>
                                                    <span className='n-time text-muted'>
                                                        <i className='icon feather icon-clock m-r-10' />
                                                        yesterday
                                                    </span>
                                                </p>
                                                <p>
                                                    Purchase New Theme and make
                                                    payment
                                                </p>
                                            </Media.Body>
                                        </Media>
                                    </ListGroup.Item>
                                </ListGroup>
                            </PerfectScrollbar>
                            <div className='noti-footer'>
                                <Link to='#'>show all</Link>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </ListGroup.Item> */}
                {/* <ListGroup.Item as='li' bsPrefix=' '>
                    <Dropdown>
                        <Dropdown.Toggle
                            as={Link}
                            variant='link'
                            to='#'
                            className='displayChatbox'
                            onClick={() => setListOpen(true)}
                        >
                            <i className='icon feather icon-mail' />
                        </Dropdown.Toggle>
                    </Dropdown>
                </ListGroup.Item> */}
                <ListGroup.Item as='li' bsPrefix=' ' action=''>
                    <Dropdown alignRight={!rtlLayout} className='drp-user'>
                        <Dropdown.Toggle
                            as={Link}
                            variant='link'
                            to='#'
                            id='dropdown-basic'
                        >
                            <i className='icon feather icon-settings' />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            alignRight
                            className='profile-notification'
                        >
                            <div
                                className='pro-head'
                                onClick={(e) => {
                                    e.currentTarget.parentElement.classList.toggle(
                                        'show'
                                    )
                                    e.currentTarget.parentElement.parentElement.classList.toggle(
                                        'show'
                                    )
                                }}
                            >
                                <img
                                    src={avatar2}
                                    className='img-radius'
                                    alt='User Profile'
                                />
                                <span>
                                    {userData &&
                                        userData.first_name &&
                                        userData.first_name}{' '}
                                    {userData &&
                                        userData.last_name &&
                                        userData.last_name}
                                </span>
                                <Link
                                    to='#'
                                    className='dud-logout'
                                    title='Edit Profile'
                                    onClick={handleEditUserProfile}
                                >
                                    <i className='feather icon-edit' />
                                </Link>
                            </div>
                            <ListGroup
                                as='ul'
                                bsPrefix=' '
                                variant='flush'
                                className='pro-body'
                            >
                                {/* <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item">
                    <i className="feather icon-settings" /> Settings
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item">
                    <i className="feather icon-user" /> Profile
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item">
                    <i className="feather icon-mail" /> My Messages
                  </Link>
                </ListGroup.Item> */}
                                <ListGroup.Item
                                    as='li'
                                    bsPrefix=' '
                                    onClick={(e) => {
                                        e.currentTarget.parentElement.parentElement.classList.toggle(
                                            'show'
                                        )
                                        e.currentTarget.parentElement.parentElement.parentElement.classList.toggle(
                                            'show'
                                        )
                                    }}
                                >
                                    <Link
                                        to='#'
                                        className='dropdown-item'
                                        onClick={showChangePasswordModal}
                                    >
                                        <i className='feather icon-lock' />{' '}
                                        Change Password
                                    </Link>
                                </ListGroup.Item>
                                <ListGroup.Item as='li' bsPrefix=' '>
                                    <Link
                                        to='#'
                                        className='dropdown-item'
                                        onClick={handleLogout}
                                    >
                                        <i className='feather icon-log-out' />{' '}
                                        Logout
                                    </Link>
                                </ListGroup.Item>
                            </ListGroup>
                        </Dropdown.Menu>
                    </Dropdown>
                </ListGroup.Item>
            </ListGroup>
            <ChatList listOpen={listOpen} closed={() => setListOpen(false)} />
            <ChangePassword
                isVisible={isShowChangePassword}
                cancelChangePassword={cancelChangePassword}
                handleChangePassword={handleChangePassword}
            />
        </React.Fragment>
    )
}

export default NavRight
