import React, { useRef, useState } from 'react'

import { Modal, Input, Select, Button } from 'antd'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'

const ChangePassword = (props) => {
  const formRef = useRef()

  const { Option } = Select

  return (
    <React.Fragment>
      <Modal
        title='Change Password'
        open={props.isVisible}
        onCancel={() => {
          formRef.current.resetForm()
          props.cancelChangePassword()
        }}
        className='antd-modal'
        footer={[
          <Button
            onClick={() => {
              formRef.current.resetForm()
              props.cancelChangePassword()
            }}
          >
            Cancel
          </Button>,
          <Button
            key='draft'
            type='primary'
            onClick={() => {
              formRef.current.submitForm()
              // props.handleAddInterest()
            }}
          >
            Change Password
          </Button>,
        ]}
        maskClosable={false}
      >
        <Formik
          initialValues={{
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
          }}
          validationSchema={Yup.object().shape({
            currentPassword: Yup.string()
              .min(6)
              .required('Current Password is required'),
            newPassword: Yup.string()
              .min(6)
              .required('New Password is required'),
            confirmPassword: Yup.string()
              .min(6)
              .required('Confirm Password is required')
              .oneOf([Yup.ref('newPassword'), ''], 'Password must match'),
          })}
          onSubmit={props.handleChangePassword}
          innerRef={formRef}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
            touched,
            values,
            resetForm,
            handleReset,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <div className='form-group mb-3'>
                <Input.Password
                  size='large'
                  placeholder='Current Password'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.currentPassword}
                  name='currentPassword'
                />
                {touched.currentPassword && errors.currentPassword && (
                  <small className='text-danger form-text text-left'>
                    {errors.currentPassword}
                  </small>
                )}
              </div>
              <div className='form-group mb-3'>
                <Input.Password
                  size='large'
                  placeholder='New Password'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.newPassword}
                  name='newPassword'
                />
                {touched.newPassword && errors.newPassword && (
                  <small className='text-danger form-text text-left'>
                    {errors.newPassword}
                  </small>
                )}
              </div>
              <div className='form-group mb-3'>
                <Input.Password
                  size='large'
                  placeholder='Confirm Password'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirmPassword}
                  name='confirmPassword'
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <small className='text-danger form-text text-left'>
                    {errors.confirmPassword}
                  </small>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </React.Fragment>
  )
}

export default ChangePassword
