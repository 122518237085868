import axios from 'axios'

// axiosServices.interceptors.response.use(
//     (response) => response,
//     (error) => Promise.reject(error || 'Wrong Services')
// )

// export default axiosServices

let isRefreshing = false
let failedQueue = []
// const API_URL = 'https://api.travelpalsglobal.com'
const API_URL = process.env.REACT_APP_BACKEND_URL

// const API_URL = 'http://localhost:3000'

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error)
        } else {
            prom.resolve(token)
        }
    })
    failedQueue = []
}
const axiosServices = axios.create({
    baseURL: API_URL,
    timeout: 20000,
    headers: {
        'Content-Type': 'application/json',
        language: 'english',
    },
})

axiosServices.interceptors.request.use(
    (config) => {
        const token = JSON.parse(localStorage.getItem('travelpals_token'))
        config.headers = {
            'Content-Type': 'application/json',
            language: 'english',
            authorization: 'Bearer ' + token,
        }
        return config
    },
    (error) => {
        Promise.reject(error)
    }
)

axiosServices.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        const originalRequest = error.config
        if (error.response.data.code == 401) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject })
                })
                    .then((token) => {
                        originalRequest.headers.authorization = `Bearer ${token}`
                        return axios(originalRequest)
                    })
                    .catch((error) => {
                        return Promise.reject(error)
                    })
            }
            isRefreshing = true
            return new Promise((resolve, reject) => {
                let refresh_token = localStorage.getItem('refresh_token')
                axiosServices
                    .post('/api/v1/admin/refresh-token', {
                        refresh_token: refresh_token,
                    })
                    .then((response) => {
                        localStorage.setItem(
                            'travelpals_token',
                            JSON.stringify(response.data.data.access_token)
                        )
                        axiosServices.defaults.headers.common.authorization = `Bearer ${response.data.data.access_token}`
                        originalRequest.headers.authorization = `Bearer ${response.data.data.access_token}`
                        processQueue(null, response.data.data.access_token)
                        resolve(axios(originalRequest))
                    })
                    .catch((error) => {
                        processQueue(error, null)
                        reject(error)
                    })
                    .finally(() => {
                        isRefreshing = false
                    })
            })
        }
        return Promise.reject(error)
    }
)
export default axiosServices
