import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'

//toast messages
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

//antd design style
import 'antd/dist/antd.css'

// material icons

import 'material-icons/iconfont/material-icons.css'

// bootstrap style
// import 'bootstrap/dist/css/bootstrap.css'

import { Provider } from 'react-redux'
import { ConfigProvider } from './contexts/ConfigContext'

import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './store'

// import { library  } from '@fortawesome/fontawesome-svg-core'
// import { fab } from '@fortawesome/free-brands-svg-icons'
// import { faAddressCard , faFileAlt } from '@fortawesome/free-regular-svg-icons'
// import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'

// library.add(fab, faAddressCard , faFileAlt , faPhoneAlt)

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider>
            <App />
        </ConfigProvider>
        <ToastContainer autoClose={5000} />
    </Provider>,
    document.getElementById('root')
)

reportWebVitals()
